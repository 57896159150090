export default DashboardLayout;

import {
  Anchor,
  AppShell,
  Avatar,
  Burger,
  Flex,
  Group,
  NavLink,
  ScrollArea,
  Text,
} from "@mantine/core";
import {
  IconApi,
  IconArrowBigUpLineFilled,
  IconBlur,
  IconCoin,
  IconColorSwatch,
  IconCropPortrait,
  IconFileText,
  IconFileTextAi,
  IconHome2,
  IconLanguage,
  IconMaximize,
  IconMicrophone,
  IconPhoto,
  IconPhotoAi,
} from "@tabler/icons-react";
import React, { useRef } from "react";
import { useResize, animated } from "@react-spring/web";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { usePageContext } from "../renderer/usePageContext";
import { Logo } from "../components/Logo";
import { Link } from "../components/Link";
import { HOME } from "../constants/Routes";

import "@mantine/core/styles/Group.css";
import "@mantine/core/styles/Burger.css";
import "@mantine/core/styles/Flex.css";
import "@mantine/core/styles/NavLink.css";
import "@mantine/core/styles/Avatar.css";
import "@mantine/core/styles/ScrollArea.css";
import "@mantine/core/styles/Anchor.css";
import useMe from "../api/authentication/useMe";
import { IconPhotoScan } from "@tabler/icons-react";
import { IconCreditCard } from "@tabler/icons-react";
import { IconFileTypeDoc } from "@tabler/icons-react";

function DashboardLayout({
  padding,
  children,
}: {
  padding?: number;
  children: React.ReactNode;
}) {
  const { data: user } = useMe();
  const { urlPathname } = usePageContext();
  const [opened, { toggle }] = useDisclosure();
  const isSM = useMediaQuery("(max-width: 48em)");

  const containerRef = useRef<HTMLDivElement | null>(null);
  const { width, height } = useResize({
    container: containerRef,
    config: {
      tension: 250,
      bounce: 0,
    },
  });

  return (
    <AppShell
      id="page-container"
      header={{ height: 70, collapsed: !isSM }}
      navbar={{
        width: 300,
        breakpoint: "sm",
        collapsed: { mobile: !opened },
      }}
    >
      <AppShell.Header
        id="header"
        style={{
          padding: "10px 20px",
        }}
      >
        <Flex style={{ height: "100%" }} justify="space-between" align="center">
          <Link to={HOME} aria-label="Home" style={{ padding: 0 }}>
            <Logo />
          </Link>

          <Burger
            size="sm"
            hiddenFrom="sm"
            opened={opened}
            onClick={toggle}
            aria-label="Toggle navigation"
          />
        </Flex>
      </AppShell.Header>

      <AppShell.Navbar id="navbar" px="lg" pt="lg" pb="md" style={{}}>
        <AppShell.Section mt={-5} mb={30} visibleFrom="sm">
          <Link to="/">
            <Logo />
          </Link>
        </AppShell.Section>

        <AppShell.Section grow component={ScrollArea}>
          <NavLink
            href="/app"
            label="Tools"
            variant="light"
            active={urlPathname === "/app"}
            leftSection={<IconPhotoAi width={22} height={22} stroke={1.5} />}
            styles={{
              label: {
                fontWeight: 500,
              },
            }}
          />
          <NavLink
            href="/app/manage-api"
            label="Manage API"
            variant="light"
            active={urlPathname === "/app/manage-api"}
            leftSection={<IconApi width={22} height={22} stroke={1.5} />}
            styles={{
              label: {
                fontWeight: 500,
              },
            }}
          />
          <NavLink
            variant="light"
            label="Billing"
            href="/app/billing"
            active={urlPathname === "/app/billing"}
            leftSection={<IconCreditCard width={22} height={22} stroke={1.5} />}
            styles={{
              label: {
                fontWeight: 500,
              },
            }}
          />
          <NavLink
            href="https://developer.imggen.ai/docs/getting-started"
            label="API Docs"
            target="_blank"
            // active={urlPathname === "/app/api-docs"}
            leftSection={
              <IconFileTypeDoc width={22} height={22} stroke={1.5} />
            }
            styles={{
              label: {
                fontWeight: 500,
              },
            }}
          />

          {/* <NavLink
            href="/app/usage"
            label="Usage"
            active={urlPathname === "/app/usage"}
            leftSection={<IconCoin width={22} height={22} stroke={1.5} />}
            styles={{
              label: {
                fontWeight: 500,
              },
            }}
          /> */}
        </AppShell.Section>

        <AppShell.Section>
          {/* <NavLink
            href="/app/upgrade"
            variant="light"
            label="Upgrade to Imggen Plus"
            leftSection={
              <IconArrowBigUpLineFilled width={22} height={22} stroke={1.5} />
            }
            styles={{
              root: {
                backgroundColor: "var(--mantine-color-yellow-1)",
              },
              label: {
                fontWeight: 500,
              },
            }}
          /> */}

          <Anchor href="/app/profile" variant="text" underline="never">
            <Group mt={12} className="user-card" gap="xs">
              <Avatar size="md" color="primary" variant="filled" radius="xl">
                {user?.first_name?.[0]}
                {user?.last_name?.[0]}
              </Avatar>
              <Text fw={500}>
                {user?.first_name} {user?.last_name}
              </Text>
            </Group>
          </Anchor>
        </AppShell.Section>
      </AppShell.Navbar>

      <AppShell.Main ref={containerRef}>
        <animated.div
          id="page-content"
          style={{ width, padding: padding ?? 24 }}
        >
          {children}
        </animated.div>
      </AppShell.Main>
    </AppShell>
  );
}
